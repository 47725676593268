<template>
    <div>
        <div style="width: 100%;float: left;margin-top: 20px">
            <div style="width: 100%;text-align: right;font-size: 1.025rem;padding-right: 10%">
                <count-d v-bind:end-date="endDate"></count-d>
            </div>
        </div>
        <Card style="margin-top: 20px; width: 80%;float:left; margin-left: 10%;padding-bottom: 20px">
            <Row>
                <div style="text-align: center;margin-top: 10px;margin-bottom: 20px">
                    <Avatar size="100" icon="ios-person"></Avatar>
                    <div style="font-weight: 500;font-size: 1.15rem;margin-top: 20px"> {{userName}}</div>
                </div>
            </Row>

            <Row>
                <Col span="16" offset="4" >
                    <div style="width: 100%; margin-top:10px">
                        <div style="text-align: left;font-size: 1.025rem;font-weight: 500;margin-bottom: 5px">Outline</div>
                        <div style="text-align: left;margin-bottom: 25px;">
                            {{outline}}
                        </div>
                    </div>
                    <div style="width: 100%; margin-top:10px">
                        <div class="petitle">Price of executable code</div>
                        <Table :columns="columns1" :data="so_price"></Table>
                    </div>
                    <div style="width: 100%; margin-top: 20px">
                        <div class="petitle">Price of source code</div>
                        <Table :columns="columns1" :data="ex_price"></Table>
                    </div>

                </Col>
            </Row>


            <Row>
                <Col span="16" offset="4" >
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-top:50px;margin-bottom: 5px;text-align: left">
                        Voting time for Join/Extend
                        <p style="font-size: 14px;color:darkgray;display: inline">( minutes )</p>:
                    </div>
                    <p> {{joinVotingTime}} minutes</p>


                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-top:50px;margin-bottom: 5px;text-align: left">
                        Voting time for amendment
                        <p style="font-size: 14px;color:darkgray;display: inline">( days )</p>:
                    </div>
                    <p> {{amendmentVotingTime}} days</p>
                </Col>
            </Row>


            <Divider v-show="!vote_state"></Divider>

            <Button v-show="!vote_state"  @click="agree">
                agree
            </Button>
            <Modal
                    v-model="modal1"
                    title="Confirm"
                    ok-text="OK"
                    cancel-text="Cancel"
                    @on-ok="OK1">
                <p>You agree with this case.</p>
            </Modal>
            <Button v-show="!vote_state" type="primary" style="margin-left: 30%" @click="disagree">
                disagree
            </Button>
            <Modal
                    v-model="modal2"
                    title="Confirm"
                    ok-text="OK"
                    cancel-text="Cancel"
                    @on-ok="OK2">
                <p>You disagree with this case.</p>
            </Modal>
        </Card>
        <div style="width: 100%;float: left;margin-top: 30px">
            <Divider></Divider>
            <div style="width: 100%;float: left;font-size: 1.25rem;text-align: left;font-weight: 500; margin-bottom: 20px">
                Voting details</div>
            <voting-pic v-bind:reject="reject" v-bind:pending="pending" v-bind:approve="approve"></voting-pic>

            <div style="width: 100%; float:left; margin-left:0% ;padding-left:  0%;padding-right:5%;padding-bottom: 50px">
                <div style="width: 50%;float:left;padding-right:20%;margin-bottom: 20px;text-align: right">
                    <Input search placeholder="find center person"></Input>
                </div>
                <div style="width: 50%;float: right;margin-bottom: 40px;text-align: right">
                    <Dropdown style="text-align: left">
                        <Button type="primary">
                            Sort
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <DropdownItem>Latest</DropdownItem>
                            <DropdownItem>Share</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Button style="margin-left: 12%" type="primary" @click="md4" >Add</Button>
                    <Modal v-model="modal3"
                           title="Make a discussion with the applicant"
                           @on-ok="ok3"
                           >
                        <div>
                            <div>
                                <Input v-model="further_reason" type="textarea" placeholder="add more info...." style="margin-top:10px; width: 80%; margin-left: 10%" />
                            </div>
                        </div>
                    </Modal>
                </div>
                <Divider></Divider>
                <div style="width: 100%; float: left">
                    <List item-layout="vertical">
                        <div v-for="item in data" :key="item.title" style="width: 100%;float: left; min-height: 100px">
                            <div style="width: 30%;padding-bottom:30px;float: left">
                                <div style="width: 100%;float: left"><Avatar icon="ios-person" size="70"/></div>
                                <div style="width: 100%;float: left;margin-top: 20px;font-weight: 500;font-size: 1.025rem">{{item.user_name}}</div>
                            </div>
                            <div style="width: 60%;float: left">
                                {{ item.content }}
                            </div>
                            <Divider></Divider>
                        </div>
                    </List>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CountD from "./countD";
    import VotingPic from "./votingPic";
    export default {
        name: "projectVersion",
        components: {VotingPic, CountD},
        data(){
            return{
                userName:"",
                reject:0,
                pending:0,
                approve: 0,
                share:0,
                so_price:[],
                ex_price:[],
                outline:[],
                modal1:false,
                modal2:false,
                modal3:false,
                endDate: null,
                vote_state: false,
                further_reason: null,
                joinVotingTime: 0,
                amendmentVotingTime:0,
                columns1: [
                    {
                        title: 'Total Profit',
                        key: 'profit'
                    },
                    {
                        title: 'Price',
                        key: 'price'
                    }
                ],
                data:[],

            }

        },
        methods:{
            agree(){
                this.modal1 = true;
            },
            disagree(){
                this.modal2 = true;
            },
            md4(){
                this.modal3 = true;
            },
            OK1(){
                if(this.endDate === null){
                    let curDate = new Date();
                    this.endDate = new Date(curDate.getTime() + 5*60*1000);
                }
                this.approve = this.approve + this.share;
                this.pending = this.pending - this.share;
                this.vote_state = true;
                console.log(this.endDate);
                console.log(this.approve);
            },
            OK2(){
                if(this.endDate === null){
                    let curDate = new Date();
                    this.endDate = new Date(curDate.getTime() + 5*60*1000);
                }
                this.reject = this.reject + this.share;
                this.pending = this.pending - this.share;
                this.vote_state = true;
                console.log(this.endDate);
                console.log(this.reject);
            },
            ok3(){

            },
            make_fake() {
                this.userName = "Test User";
                this.so_price = [
                    {profit: 300, price: 500},
                    {profit: 1000, price: 400},
                    {profit: 1200, price: 300},
                ];
                this.ex_price = [
                    {profit: 300, price: 500},
                    {profit: 1000, price: 400},
                    {profit: 1200, price: 300},
                ]
                this.share = 10;
                this.outline = "I think we can adjust our price to enlarge our competence."
                this.data=[
                    {
                        title: 'This is title 1',
                        user_name:'jkkk',
                        description: 'This is description, this is description, this is description.',
                        //avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
                        content: 'This is the content, this is the content, this is the content, this is the content.'
                    },
                    {
                        title: 'This is title 2',
                        user_name:'jkkk',
                        description: 'This is description, this is description, this is description.',
                        //avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
                        content: 'This is the content, this is the content, this is the content, this is the content.'
                    },
                    {
                        title: 'This is title 3',
                        user_name:'jkkk',
                        description: 'This is description, this is description, this is description.',
                        //avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
                        content: 'This is the content, this is the content, this is the content, this is the content.'
                    }
                ];
            }
        },
        mounted() {
            this.make_fake()
        },
        watch:{
            endDate(newVal, oldVal){
                this.endDate = newVal
            }
        }


    }
</script>

<style scoped>
    .petitle{
        text-align: left;
        font-size: 1.025rem;font-weight: 500;
        margin-bottom: 15px;
    }
</style>
